import styled, { system, x } from '@xstyled/styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';

import _DeveloperSVG from 'assets/svg/developer.svg';

export const Section = styled(x.section).attrs((props) => ({
	mx: 'auto',
	py: { _: 14, md: 20 },
	...props,
}))`
	max-width: 1200;
`;

export const CoveredSection = styled(x.section).attrs((props) => ({
	mx: { _: -6, md: -8 },
	py: { _: 14, md: 20 },
	px: { _: 6, md: 8 },
	...props,
}))`
	transition: 0.5s;
	background-color: backgroundLight;
`;

export const DeveloperSVG = styled(_DeveloperSVG)`
	width: 780px;
	height: auto;

	@media (min-width: _) and (max-width: md) {
		width: 100%;
	}
`;

export const MotionContainer = styled(motion.div)`
	${system}
`;

export const CompanyPicture = styled(GatsbyImage)`
	/* width: 100px; */
	height: 100px;
	margin-right: 40;

	@media (max-width: md) {
		margin-right: 20;
	}
`;

export const StackPicture = styled(GatsbyImage)`
	width: 75px;
	height: 75px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 7;
	overflow: hidden;
`;
