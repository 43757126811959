import React from 'react';

import * as Styled from './style/LineTitle.styles';

const LineTitle = ({ children, ...props }) => {
	return (
		<>
			<Styled.Line />
			<Styled.Title {...props}>{children}</Styled.Title>
		</>
	);
};

export default LineTitle;
