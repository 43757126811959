import React from "react";
import { x, useColorMode, useColor } from "@xstyled/styled-components";
import { graphql } from "gatsby";
import { ArrowheadDownOutline } from "@styled-icons/evaicons-outline";
import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";
import { getImage } from "gatsby-plugin-image";

import MotionPlayer from "components/motion/MotionPlayer";
import Button from "components/button/Button";
import LineTitle from "components/title/LineTitle";
import Link from "components/link/Link";

import sunLottie from "assets/lottie/sun.json";
import moonLottie from "assets/lottie/moon.json";

import * as Styled from "../style/index.styles";

export default function IndexPage({ data }) {
  const [colorMode] = useColorMode(null);

  const primary = useColor("primary");

  const labels = JSON.parse(data?.contentfulLabels?.labels?.internal?.content);
  const companies = data?.companies?.logos;
  const stack = data?.stack?.logos;

  return (
    <>
      {/* <x.span
							display="inline-block"
							w="fit-content"
							fontWeight="400"
							fontSize="8xl"
							color="text"
							backgroundImage="gradient-to-r"
							gradientFrom="primary"
							mt={4}
							px={8}
							pb={6}
							borderRadius={10}
						>
							Gabriel
						</x.span> */}

      <Styled.Section py={0} pb={14}>
        <x.div
          display="flex"
          alignItems="center"
          flexDirection={{ _: "column", md: "row" }}
          mb={14}
        >
          <x.div minWidth={{ _: "100%", md: "50%" }} transition="0.5s">
            <LineTitle mb={2}>{labels?.landing?.lineTitle}</LineTitle>
            <x.h1
              color="text"
              fontSize={{ _: "6xl", md: "title" }}
              fontWeight={700}
              lineHeight={{ _: "50px", md: "84px" }}
              mb={4}
              whiteSpace="nowrap"
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: labels?.landing?.title,
                }}
              />
              <x.span color="primary">.</x.span>
            </x.h1>
            <x.p
              color="text"
              fontWeight={400}
              fontSize={{ _: "2xl", md: "3xl" }}
              mb={6}
            >
              {labels?.landing?.description}
            </x.p>
            <Button>{labels?.landing?.button}</Button>
          </x.div>
          <x.div minWidth={{ _: "100%", md: "50%" }} transition="0.5s" overf>
            <motion.div
              initial={{ y: -10 }}
              animate={{ y: 10 }}
              transition={{
                repeat: Infinity,
                duration: 3,
                repeatType: "reverse",
              }}
            >
              <MotionPlayer
                src={sunLottie}
                isVisible={colorMode === "default"}
                playerProps={{ loop: true }}
                width={{ xs: "50%", sm: "50%" }}
              />
              <MotionPlayer
                src={moonLottie}
                isVisible={colorMode === "dark"}
                playerProps={{ loop: true }}
                width={{ xs: "50%", sm: "50%" }}
              />
            </motion.div>
            <motion.div
              initial={{ y: 10 }}
              animate={{ y: -10 }}
              transition={{
                repeat: Infinity,
                duration: 3,
                repeatType: "reverse",
              }}
            >
              <Styled.DeveloperSVG />
            </motion.div>
          </x.div>
        </x.div>
        <x.div w="max-content" mx="auto">
          <ArrowheadDownOutline color={primary} size={48} />
        </x.div>
      </Styled.Section>

      <Styled.CoveredSection py={0} px={0} pt={{ _: 14, md: 20 }} pb={0}>
        <x.div maxWidth={1200} mx="auto">
          <Marquee speed={50} gradient={false}>
            {companies?.map(({ title, picture }) => (
              <Styled.CompanyPicture
                key={`marquee-item_${title}`}
                image={getImage(picture)}
                alt={title}
              />
            ))}
          </Marquee>
        </x.div>
      </Styled.CoveredSection>

      <Styled.CoveredSection>
        <x.div
          display="flex"
          flexDirection={{ _: "column", md: "row" }}
          maxWidth={1200}
          mx="auto"
        >
          <x.div w={{ _: "100%", md: "40%" }} mr={20} mb={8}>
            <LineTitle mb={2}>{labels?.about?.lineTitle}</LineTitle>
            <x.h3
              color="text"
              fontSize={{ _: "4xl", md: "5xl" }}
              fontWeight={700}
              mb={4}
            >
              {labels?.about?.title}
            </x.h3>
            <x.div display="flex" alignItems="center">
              <x.span
                color="primary"
                fontSize={{ _: "7xl", md: "8xl" }}
                fontWeight={700}
                mr={4}
              >
                {new Date().getFullYear() - 2019}+
              </x.span>
              <x.span
                color="text"
                fontSize="xl"
                dangerouslySetInnerHTML={{
                  __html: labels?.about?.experienceYears,
                }}
              />
            </x.div>
          </x.div>
          <x.p
            color="text"
            fontSize={{ _: "2xl", md: "3xl" }}
            lineHeight={1.8}
            w={{ _: "100%", md: "60%" }}
          >
            {labels?.about?.text}
          </x.p>
        </x.div>
      </Styled.CoveredSection>

      <Styled.Section maxWidth={1200} mx="auto" py={{ _: 14, md: 20 }}>
        <LineTitle mb={16}>{labels?.skills?.lineTitle}</LineTitle>
        <x.div display="flex" flexWrap="wrap">
          {stack?.map(({ title, picture }) => (
            <x.div
              key={`stack-item_${title}`}
              display="flex"
              alignItems="center"
              w={{ _: "100%", sm: "50%", lg: "calc(100% / 3)" }}
              mb={{ _: 6, md: 10 }}
            >
              <Styled.StackPicture image={getImage(picture)} alt={title} />
              <x.p color="text" fontSize={{ _: "2xl", md: "3xl" }}>
                {title}
              </x.p>
            </x.div>
          ))}
        </x.div>
      </Styled.Section>

      <Styled.Section
        display="flex"
        alignItems={{ _: "unset", md: "center" }}
        flexDirection={{ _: "column", md: "row" }}
        position="relative"
      >
        <x.div
          position="absolute"
          bottom={0}
          right={{ _: "calc(50% - 24px)", md: "calc(50% - 47px)" }}
          bg="primary"
          w={{ _: "48px", md: 94 }}
          h={{ _: "24px", md: 47 }}
          style={{
            borderTopLeftRadius: "100px",
            borderTopRightRadius: "100px",
          }}
        />
        <x.div flex="1" mb={{ _: 8, md: 0 }}>
          <LineTitle mb={3}>{labels?.contact?.lineTitle}</LineTitle>
          <x.h3
            color="text"
            fontSize={{ _: "4xl", md: "5xl" }}
            fontWeight={700}
            mb={4}
            dangerouslySetInnerHTML={{ __html: labels?.contact?.title }}
          />
        </x.div>
        <x.div
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems={{ _: "flex-start", md: "flex-end" }}
        >
          <x.p color="text" fontSize={{ _: "large", md: "xl" }} mb={6}>
            {labels?.contact?.text}
          </x.p>
          <Link
            href={`mailto:${labels?.contact?.email}`}
            isExternal={true}
            color="primary"
            fontSize={{ _: "large", md: "xl" }}
            fontWeight={700}
            textDecoration="unset"
            mb={4}
          >
            {labels?.contact?.email}
          </Link>
          <Link
            href={`tel:${labels?.contact?.tel.replace(/\s/g, "")}`}
            isExternal={true}
            color="text"
            fontSize={{ _: "xl", md: "2xl" }}
            fontWeight={700}
            textDecoration="unset"
          >
            {labels?.contact?.tel}
          </Link>
          <Link
            href={`tel:${labels?.contact?.swissTel.replace(/\s/g, "")}`}
            isExternal={true}
            color="text"
            fontSize={{ _: "xl", md: "2xl" }}
            fontWeight={700}
            textDecoration="unset"
          >
            {labels?.contact?.swissTel}
          </Link>
        </x.div>
      </Styled.Section>
    </>
  );
}

export const query = graphql`
  query Home {
    contentfulLabels(pageName: { eq: "home" }) {
      labels {
        internal {
          content
        }
      }
    }
    companies: contentfulLogos(name: { eq: "companies" }) {
      logos {
        title
        picture: gatsbyImageData(
          height: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, PNG]
          layout: FIXED
        )
      }
    }
    stack: contentfulLogos(name: { eq: "stack" }) {
      logos {
        title
        picture: gatsbyImageData(
          height: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, PNG]
        )
      }
    }
  }
`;
