import styled, { css, system } from '@xstyled/styled-components';
import { variant } from 'utils/xstyled/variant';

export const Button = styled.button`
	font-size: xl;
	line-height: 32px;
	padding-bottom: 1;
	padding-top: 1;
	padding-left: 6;
	padding-right: 6;
	border-radius: full;
	font-weight: 600;
	transition: 0.25s;

	${variant({
		default: 'primary',
		variants: {
			primary: css`
				background-color: primary;
				color: white;
				border: 2px solid;
				border-color: primary;

				:hover,
				:focus {
					outline: none;
					background-color: transparent;
					color: primary;
				}
			`,
			secondary: css`
				background-color: transparent;
				color: primary;
				border: 2px solid;
				border-color: primary;

				:hover,
				:focus {
					outline: none;
					background-color: primary;
					color: white;
				}
			`,
		},
	})}

	${system}
`;
