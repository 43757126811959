import styled, { x } from '@xstyled/styled-components';

export const Line = styled(x.span)`
	display: inline-block;
	width: 68px;
	height: 4px;
	background-color: text;
	vertical-align: super;
	margin-right: 2;

	@media (min-width: _) and (max-width: md) {
		width: 34px;
		height: 2px;
	}
`;

export const Title = styled(x.h2)`
	display: inline-block;
	color: text;
	font-size: 3xl;
	font-weight: 700;
	text-transform: uppercase;

	@media (min-width: _) and (max-width: md) {
		font-size: 2xl;
	}
`;
