import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { AnimatePresence } from 'framer-motion';
import { useBreakpoint } from '@xstyled/styled-components';
import PropTypes from 'prop-types';

import * as Styled from './style/MotionPlayer.styles';

const MotionPlayer = ({ src, isVisible, width, playerProps, ...props }) => {
	const breakpoint = useBreakpoint();

	return (
		<AnimatePresence>
			{isVisible && (
				<Styled.Root
					initial={{ x: '-100vw', width: 0, opacity: 0 }}
					animate={{
						x: 0,
						y: 0,
						width: width?.[breakpoint] || '100%',
						opacity: 1,
					}}
					exit={{ x: '100vw', width: 0, opacity: 0 }}
					transition={{
						ease: 'easeOut',
						duration: 1,
					}}
					zIndex={1}
					{...props}
				>
					<Player src={src} {...playerProps} />
				</Styled.Root>
			)}
		</AnimatePresence>
	);
};

MotionPlayer.propTypes = {
	src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	isVisible: PropTypes.bool,
	width: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
		PropTypes.object,
	]),
	playerProps: PropTypes.shape(Player.propTypes),
};

MotionPlayer.defaultProps = {
	src: null,
	isVisible: true,
	width: null,
	playerProps: {
		autoplay: true,
		loop: false,
		keepLastFrame: true,
	},
};

export default MotionPlayer;
